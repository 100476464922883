import React, { Component, Fragment, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
import StoreIcon from '@material-ui/icons/Store';
import ListIcon from '@material-ui/icons/List';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../assets/images/logo.png'
import styles from './styles.scss'

function DrawerContainer(props) {
  const [open, toggleDrawer] = useState(false);
  return (
    <Fragment>
      <MenuIcon onClick={() => toggleDrawer(true)} className={styles.menuIcon}/> 
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <div
          className={styles.list}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
          </List>
          <Divider />
          <List>
          </List>
        </div>
      </Drawer>
    </Fragment>
  )
}

function Header(props) {
  const {
    location: {
      pathname
    }
  } = props
  return (
    <AppBar position="static" color="default" elevation={0} className={styles.appBar} background>
      <Toolbar className={styles.toolbar}>
        <div className={styles.toolbarTitle}>
          <img src={Logo} style={{width: '175px'}}/>
        </div>

        <div className={styles.drawerContainer}>
          <DrawerContainer {...props} />
        </div>

        <div className={styles.linksContainer}>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Header);
