import React from 'react';
import Container from '@material-ui/core/Container';
import styles from './styles.scss'
import Flexbox from 'flexbox-react'
import VeteranOwned from '../../assets/images/veteran_owned.jpg'

function Footer() {
  return (
    <Container component="footer" className={styles.footerOuterContainer}>
      <Flexbox flexDirection="column" className={styles.footerInnerContainer}>
        <Flexbox className={styles.footerFirstRow} flexDirection="row">
          <Flexbox className={styles.footerRowSpacing}>29 Richwine Rd. Sherman's Dale, PA 17090</Flexbox>
          <Flexbox className={styles.footerRowSpacing}>(717)-756-3514</Flexbox>
          <Flexbox className={styles.footerRowSpacing}>arnoldstreefarm@gmail.com</Flexbox>
        </Flexbox>

        <Flexbox flexDirection="row" className={styles.footerSecondRow} alignItems="center">
          <Flexbox className={styles.familyOwnedText}>Family owned and operated by Jordan Farson and Joshua Arnold. Website created by Gabe Harms</Flexbox>
          <Flexbox><img src={VeteranOwned} className={styles.veternImg}/></Flexbox>
        </Flexbox>
      </Flexbox>
    </Container>
  )
}

export default Footer;
