import React, { Component, Fragment } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import Flexbox from 'flexbox-react'
import styles from './styles.scss'

const directionsUrl = "https://www.google.com/maps/place/Arnold's+Christmas+Tree+Farm/@40.3181157,-77.1403632,16z/data=!4m12!1m6!3m5!1s0x0:0x48283a61035170c6!2sArnold's+Christmas+Tree+Farm!8m2!3d40.3175758!4d-77.139977!3m4!1s0x0:0x48283a61035170c6!8m2!3d40.3175758!4d-77.139977"

class MapContainer extends React.Component {
  state = {
    showingInfoWindow: true,  //Hides or the shows the infoWindow
    activeMarker: {},          //Shows the active marker upon click
  };
  constructor(props) {
    super(props);
    this.markerRef = React.createRef();
    this.onMarkerClick = this.onMarkerClick.bind(this);
    setTimeout(() => {
      this.onMarkerClick()
    }, 200)
  }

  onMarkerClick = (props, marker, e) => {
    return this.setState({
      activeMarker: this.markerRef.current.marker,
      showingInfoWindow: true
    });
  }

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  render() {
    return (
      <Map google={this.props.google} initialCenter={{ lat: 40.317723, lng: -77.139998 }} className={styles.map}>
        <Marker ref={this.markerRef} onClick={this.onMarkerClick} name="Arnold's Christmas Tree Farm"/>
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>Arnold's Christmas Tree Farm</h4>
            <div>29 Richwine Rd.</div>
            <div>Sherman's Dale, PA</div>
            <div>17090</div>
            <div className={styles.mapDirectionsButtonContainer}>
              <a className={`MuiButtonBase-root MuiButton-root MuiButton-outlined ${styles.directionsButton}`} target="_blank" href={directionsUrl}>Directions</a>
            </div>
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  //apiKey: 'AIzaSyDAKarOBtUg4QQmHlxS7yYyInW-Vwc6IVk' old key
  apiKey: 'AIzaSyDTJ7U4wv_u9tH9dv_JfDKBNK5nELokt5A'
})(MapContainer);

