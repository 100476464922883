import React, { Component, Fragment } from 'react';
import Flexbox from 'flexbox-react'
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import styles from './styles.scss'

function TreeCard(props) {
  return (
    <Fragment>
      <Flexbox flexDirection="column">
        <Flexbox>
          <GridListTile key={props.label} className={styles.treeCardContainer}>
            <img src={props.img} alt={props.label} />
            <GridListTileBar
              className={styles.treeCardTitle}
              title={props.label}
            />
          </GridListTile>
        </Flexbox>
        <Flexbox style={{marginLeft: '40px'}}>
          <p>{props.scarce ? '* while supplies last' : ''}</p>
        </Flexbox>
      </Flexbox>
    </Fragment>
  )
}

export default TreeCard;
