import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Header from '../components/Header'
import SplashImage from '../components/SplashImage'
import Body from '../components/Body'
import Footer from '../components/Footer'

import Home from '../views/Home';
import Visit from '../views/Visit';
import Pricing from '../views/Pricing';
import Variety from '../views/Variety';
import About from '../views/About';
import ContactUs from '../views/ContactUs';

import * as routes from '../constants/routes';

const Routes = () => (
  <Fragment>
    <Route
      exact
      path={routes.HOME}
      component={() => (
        <Home />
      )}
    />
  </Fragment>
);

class App extends Component {
  state = {};

  render() {


    return (
      <Router>
        <div className="App">

          <Header />
          <SplashImage />

          <Body>
            <Routes />
          </Body>
        </div>
      </Router>
    );
  }
}

export default App 
