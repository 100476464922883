import React from 'react';
import Container from '@material-ui/core/Container';
import Flexbox from 'flexbox-react'
import styles from './styles.scss'

function Body({children}) {
  return (
    <Container component="main" className={styles.bodyContainerOuter}>
      {children}
    </Container>
  )
}

export default Body

