import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import styles from './styles.scss'

function SplashImage() {
  return (
    <Container component="main" className={styles.splashImageContainer}>
      <Typography component="h1" variant="h1" align="center" color="white" gutterBottom className={styles.title}>
        We regret to announce our permanent closure.
      </Typography>
      <Typography variant="h4" align="center" color="white" component="p" className={styles.text}>
        We appreciate your business and support. Have a merry christmas.
      </Typography>
    </Container>
  )
}

export default SplashImage;
