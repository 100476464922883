import React, { useState, Component, Fragment } from 'react';
import Flexbox from 'flexbox-react'
import styles from './styles.scss'
import MapContainer from './MapContainer'
import getVideoId from './fetchYoutubeVideo'

const hours = [
  {day: 'Fridays', hours: '12pm-6pm'},
  {day: 'Saturday', hours: '8am-6pm'},
  {day: 'Sunday', hours: '8am-6pm'},
  {day: 'Last day (Dec 18th)', hours: '8am-1pm'},
]

const Home = (props) => {
  return (
    <div>
    </div>
  )
}

/* YOUTUBE VIDEO HOME PAGE
const DEFAULT_VIDEO_ID = 'dWbvnjmqO9E'

const Home = (props) => {

  const [videoId, setVideoId] = useState('');

  if (videoId == '') {
    getVideoId((newestVideoId) => setVideoId(newestVideoId || DEFAULT_VIDEO_ID))
  }

  return (
    <Flexbox flexDirection="row" justifyContent="center" className={styles.container}>
      <Flexbox>
        <iframe className={styles.youtubeVideo} src={`https://www.youtube.com/embed/${videoId}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Flexbox>
    </Flexbox>
  )
}
*/


export default Home;
