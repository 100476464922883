import React, { Component, Fragment } from 'react';
import Flexbox from 'flexbox-react'
import styles from './styles.scss'
import TreeCard from './TreeCard'
import GridList from '@material-ui/core/GridList';
import Douglas from '../../assets/images/douglas_fir.png'
import Concolor from '../../assets/images/concolor_fir.jpg'
import Fraiser from '../../assets/images/fraiser_fir.jpg'
import Canaan from '../../assets/images/canaan_fir.jpg'
import Norway from '../../assets/images/norway_spruce.jpg'
import Balsam from '../../assets/images/balsam_fir.jpg'

const trees = [
  {
    label: 'Douglas Fir',
    img: Douglas 
  },
  {
    label: 'Concolor Fir',
    img: Concolor 
  },
  //{
    //label: 'Canaan Fir',
    //img: Canaan
  //},
  //{
    //label: 'Norway Spruce',
    //img: Norway
  //},
  //{
    //label: 'Balsam Fir',
    //img: Balsam
  //},
]

function Variety(props) {
  return (
    <Fragment>
      <Flexbox flexDirection="column" className={styles.container}>

        <Flexbox flexDirection="column" className={styles.headerContainer}>
          <Flexbox><h1>Pre-cut</h1></Flexbox>
        </Flexbox>

        <Flexbox>
          <GridList cellHeight={150} cols={3} className={styles.gridList}>
            {trees.map(tree => (
              <TreeCard label={tree.label} img={tree.img} scarce={tree.label === 'Concolor Fir'}/>
            ))}
          </GridList>
        </Flexbox>

      </Flexbox>
    </Fragment>
  )
}

export default Variety;
