
import React, { Component, Fragment } from 'react';
import Flexbox from 'flexbox-react'
import styles from './styles.scss'
import Facebook from '../../assets/images/facebook.png'

const hours = [
  {day: 'Monday', hours: '12pm-6pm'},
  {day: 'Tuesday', hours: '12pm-6pm'},
  {day: 'Wednesday', hours: '12pm-6pm'},
  {day: 'Thursday', hours: '12pm-6pm'},
  {day: 'Friday', hours: '12pm-6pm'},
  {day: 'Saturday', hours: '8am-6pm'},
  {day: 'Sunday', hours: '8am-6pm'},
]

function ContactUs() {
  return (
    <Flexbox flexDirection="row" justifyContent="space-between" className={styles.contentContainer}>

      <Flexbox flexDirection="column" className={styles.contactInfoContainer}>
        <Flexbox><h1 className={styles.hoursHeader}>Contact Info</h1></Flexbox>

        <Flexbox flexDirection="row" className={styles.contactDetailsContainer}>
          <Flexbox flexDirection="column" className={styles.contactDetailLabels} >
            <Flexbox>Address:</Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox>Email:</Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox>Phone:</Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox>Social:</Flexbox>
          </Flexbox>

          <Flexbox flexDirection="column"  className={styles.hoursRow} >
            <Flexbox>29 Richwine Rd. <br/>Sherman's Dale, PA<br/>17090</Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox>arnoldstreefarm@gmail.com</Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox>(717)-756-3514</Flexbox>
            <Flexbox><br/></Flexbox>
            <Flexbox><img src={Facebook} className={styles.facebook} onClick={() => window.open('https://www.facebook.com/Arnolds-Christmas-Tree-Farm-255359917855392/', '_blank')} /></Flexbox>
          </Flexbox>

        </Flexbox>
    </Flexbox>


    <Flexbox flexDirection="column" className={styles.hoursContainer}>
      <Flexbox><h1 className={styles.hoursHeader}>Hours</h1></Flexbox>
      {hours.map(row => {
        return (
          <Flexbox
            flexDirection="row"
            justifyContent="space-between"
            className={styles.hoursRow}
          >
            <Flexbox>{row.day}:</Flexbox><Flexbox>{row.hours}</Flexbox>
          </Flexbox>
        )
      })}
    </Flexbox>

    </Flexbox>
  )
}

export default ContactUs;
