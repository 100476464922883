import {get} from 'axios'

let videoId = null;

get('https://www.googleapis.com/youtube/v3/search?part=id&channelId=UC666FXA9x4k2uTgWespvgQQ&order=date&type=video&key=AIzaSyDAKarOBtUg4QQmHlxS7yYyInW-Vwc6IVk').
  then(res => videoId = res.data.items[0].id);

const getVideoId = callback => {
  callback(videoId)
}

export default getVideoId
